import { useMapStore } from '../../store/mapStore';

interface ErrorAlertProps {
  message: string;
  onRetry?: () => void;
}

export function ErrorAlert({ message, onRetry }: ErrorAlertProps) {
  const { map } = useMapStore();
  
  const isNoLocationsError = message.includes('No valid locations found');
  const canZoomOut = map && map.getZoom() > 4;

  return (
    <div className="absolute top-20 left-1/2 transform -translate-x-1/2 z-10 w-full max-w-md px-4">
      <div className="bg-red-100 text-red-700 px-4 py-3 rounded relative text-sm">
        <p className="break-normal">{message}</p>
        {isNoLocationsError && canZoomOut && (
          <button
            onClick={onRetry}
            className="mt-2 text-sm bg-red-200 hover:bg-red-300 text-red-800 px-3 py-1 rounded transition-colors"
          >
            Zoom out and try again
          </button>
        )}
      </div>
    </div>
  );
}