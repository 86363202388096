import { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import type { Map as MapboxMap } from 'mapbox-gl';
import type { Location } from '../../../types';

export function useMarkers(
  map: MapboxMap | null,
  locations: Location[],
  selectedLocation: Location | null,
  onMarkerClick: (location: Location) => void
) {
  const markers = useRef<mapboxgl.Marker[]>([]);

  useEffect(() => {
    if (!map) return;

    // Clear existing markers
    markers.current.forEach(marker => marker.remove());
    markers.current = [];

    // Add new markers
    locations.forEach((location: Location) => {
      const marker = new mapboxgl.Marker({
        color: selectedLocation?.id === location.id ? '#FF0000' : '#4A90E2'
      })
        .setLngLat(location.coordinates)
        .setPopup(new mapboxgl.Popup().setHTML(`
          <h3 class="font-bold">${location.name}</h3>
          <p>${location.description}</p>
        `))
        .addTo(map);

      marker.getElement().addEventListener('click', () => {
        onMarkerClick(location);
      });

      markers.current.push(marker);
    });

    return () => {
      markers.current.forEach(marker => marker.remove());
      markers.current = [];
    };
  }, [map, locations, selectedLocation, onMarkerClick]);
}