import { StoryControls } from './StoryControls';
import { useMapStore } from '../../store/mapStore';
import { LoadingSpinner } from '../UI/LoadingSpinner';
import { useStoryPlayer } from '../../hooks/useStoryPlayer';
import { useSpring, animated } from '@react-spring/web';
import type { Location } from '../../types';

interface StoryPlayerProps {
  location: Location;
  onStoryGenerated: (locationId: string, story: string, audioUrl: string) => void;
  showControls?: boolean;
}

export function StoryPlayer({ 
  location, 
  onStoryGenerated,
  showControls = true 
}: StoryPlayerProps) {
  const { updateLocationDetails } = useMapStore();
  
  const [{ height }, api] = useSpring(() => ({
    height: location.story ? 'auto' : 0,
    config: { tension: 280, friction: 60 }
  }));

  const {
    audioRef,
    state,
    isPlaying,
    handlePlayPause,
    currentStory
  } = useStoryPlayer({
    location,
    onStoryGenerated,
    onStoryStateChange: (newState, story) => {
      if (story) {
        api.start({ 
          height: 'auto',
          immediate: false,
          config: { tension: 280, friction: 60 }
        });
      } else {
        api.start({ 
          height: 0,
          immediate: false,
          config: { tension: 280, friction: 60 }
        });
      }
    }
  });

  const getButtonLabel = () => {
    switch (state) {
      case 'generating-story':
        return "Generating Story...";
      case 'generating-audio':
        return "Generating Narration...";
      default:
        return location.audioUrl 
          ? (isPlaying ? "Pause Story" : "Play Story")
          : "Listen to Story";
    }
  };

  const isLoading = state === 'generating-story' || state === 'generating-audio';
  const showContent = state !== 'idle';
  const displayStory = location.story || currentStory;

  return (
    <div className="space-y-4">
      {showControls && (
        <StoryControls
          location={location}
          disabled={isLoading}
          onUpdate={updateLocationDetails}
        />
      )}

      <button
        onClick={handlePlayPause}
        disabled={isLoading}
        className={`${
          isLoading
            ? 'bg-gray-400'
            : 'bg-[#346DFF] hover:bg-[#2855CC]'
        } text-white px-4 py-2 rounded-md flex items-center justify-center transition-colors w-full`}
      >
        {isLoading ? (
          <div className="flex items-center space-x-2">
            <LoadingSpinner size="sm" color="#ffffff" />
            <span>{getButtonLabel()}</span>
          </div>
        ) : (
          <>
            <span className="mr-2">🎧</span>
            {getButtonLabel()}
          </>
        )}
      </button>

      <animated.div style={{ height, overflow: 'hidden' }} className="space-y-4">
        {showContent && (
          <>
            {location.audioUrl && (
              <div className="bg-gray-50 p-3 rounded-lg">
                <audio 
                  ref={audioRef}
                  controls 
                  className="w-full"
                  src={location.audioUrl}
                />
              </div>
            )}
            
            {displayStory && (
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="text-gray-700 whitespace-pre-wrap">
                  {displayStory || (state === 'generating-story' ? 'Generating story...' : 'Building narration...')}
                </p>
              </div>
            )}
          </>
        )}
      </animated.div>
    </div>
  );
}