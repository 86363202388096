import { useState, useRef, useEffect } from 'react';
import { playLocationStory } from '../services/api';
import type { Location } from '../types';

export type StoryState = 'idle' | 'generating-story' | 'generating-audio' | 'ready';

interface UseStoryPlayerProps {
  location: Location;
  onStoryGenerated: (locationId: string, story: string, audioUrl: string) => void;
  onStoryStateChange: (state: StoryState, story?: string) => void;
}

export function useStoryPlayer({ 
  location, 
  onStoryGenerated,
  onStoryStateChange 
}: UseStoryPlayerProps) {
  const [state, setState] = useState<StoryState>('idle');
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const storyRef = useRef<string | null>(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleEnded = () => setIsPlaying(false);
    const handleError = (e: Event) => {
      console.error('Audio playback error:', e);
      setIsPlaying(false);
      setState('idle');
    };

    audio.addEventListener('play', handlePlay);
    audio.addEventListener('pause', handlePause);
    audio.addEventListener('ended', handleEnded);
    audio.addEventListener('error', handleError);

    return () => {
      audio.removeEventListener('play', handlePlay);
      audio.removeEventListener('pause', handlePause);
      audio.removeEventListener('ended', handleEnded);
      audio.removeEventListener('error', handleError);
      audio.pause();
    };
  }, [location.id]);

  useEffect(() => {
    // Reset state when location changes
    setState('idle');
    storyRef.current = null;
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.src = '';
    }
    onStoryStateChange('idle');
  }, [location.id, location.timeframe, location.topics, location.storyStyle]);

  const handlePlayPause = async () => {
    if (location.audioUrl && audioRef.current) {
      if (audioRef.current.paused) {
        await audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
      return;
    }

    try {
      setState('generating-story');
      onStoryStateChange('generating-story');

      const { story, audioUrl } = await playLocationStory(
        location,
        (stage, content) => {
          if (stage === 'story' && content) {
            storyRef.current = content;
            setState('generating-audio');
            onStoryGenerated(location.id, content, '');
            onStoryStateChange('generating-audio', content);
          }
        }
      );

      setState('ready');
      onStoryGenerated(location.id, story, audioUrl);
      onStoryStateChange('ready', story);

      if (audioRef.current) {
        audioRef.current.src = audioUrl;
        audioRef.current.load();
        await audioRef.current.play();
        setIsPlaying(true);
      }
    } catch (error) {
      console.error('Story generation error:', error);
      setState('idle');
      onStoryStateChange('idle');
      throw error;
    }
  };

  return {
    audioRef,
    state,
    isPlaying,
    handlePlayPause,
    currentStory: storyRef.current
  };
}