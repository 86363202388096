import { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { useMapStore } from '../../store/mapStore';
import { useMapHandlers } from './hooks/useMapHandlers';
import { useMarkers } from './hooks/useMarkers';
import { setupNavigationControls } from './controls/NavigationControls';
import { setupLayerControls } from './controls/LayerControls';
import { INITIAL_CENTER, INITIAL_ZOOM, MIN_ZOOM, MAX_ZOOM, LAYER_CONFIGS } from './constants';

export function Map() {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const geolocateControlRef = useRef<mapboxgl.GeolocateControl | null>(null);
  const [currentZoom, setCurrentZoom] = useState<number>(INITIAL_ZOOM);
  
  const { 
    locations,
    selectedLocation, 
    setSelectedLocation, 
    setMapCenter,
    setMap,
    setDrawerOpen,
    query
  } = useMapStore();

  const { handleClick, handleDoubleClick } = useMapHandlers();

  // Setup click handlers
  useEffect(() => {
    if (!map.current) return;

    console.log('Setting up map click handlers');

    const clickHandler = (e: mapboxgl.MapMouseEvent) => {
      console.log('Raw map click event triggered');
      handleClick(e);
    };

    const dblClickHandler = (e: mapboxgl.MapMouseEvent) => {
      handleDoubleClick(e);
    };

    map.current.on('click', clickHandler);
    map.current.on('dblclick', dblClickHandler);

    return () => {
      console.log('Cleaning up map click handlers');
      map.current?.off('click', clickHandler);
      map.current?.off('dblclick', dblClickHandler);
    };
  }, [handleClick, handleDoubleClick]);

  useEffect(() => {
    const mapboxToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;
    if (!mapContainer.current || !mapboxToken) return;

    console.log('Initializing map');

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: INITIAL_CENTER,
      zoom: INITIAL_ZOOM,
      minZoom: MIN_ZOOM,
      maxZoom: MAX_ZOOM,
      accessToken: mapboxToken,
      clickTolerance: 3,
      projection: 'mercator'
    });

    map.current.on('load', () => {
      console.log('Map loaded');
      setMap(map.current);
      
      if (geolocateControlRef.current) {
        geolocateControlRef.current.trigger();
      }
    });

    // Setup controls
    const controlsContainer = document.createElement('div');
    controlsContainer.className = 'absolute top-20 left-[calc(40px-1.25rem)] flex flex-col gap-1.5';
    mapContainer.current.appendChild(controlsContainer);

    geolocateControlRef.current = setupNavigationControls(
      map.current,
      controlsContainer,
      ({ latitude, longitude }) => {
        map.current?.flyTo({
          center: [longitude, latitude],
          zoom: 11,
          essential: true,
          duration: 2000
        });
      }
    );

    setupLayerControls(map.current, mapContainer.current, LAYER_CONFIGS);
    
    map.current.on('moveend', () => {
      if (map.current) {
        const center = map.current.getCenter();
        setMapCenter([center.lng, center.lat]);
      }
    });

    map.current.on('zoom', () => {
      if (map.current) {
        setCurrentZoom(Math.round(map.current.getZoom() * 10) / 10);
      }
    });

    return () => {
      console.log('Cleaning up map');
      map.current?.remove();
      setMap(null);
      geolocateControlRef.current = null;
    };
  }, []);

  // Update cursor style based on interaction state
  useEffect(() => {
    if (!map.current) return;
    map.current.getCanvas().style.cursor = query ? 'default' : 'pointer';
  }, [query]);

  // Setup markers
  useMarkers(map.current, locations, selectedLocation, (location) => {
    console.log('Marker clicked:', location.name);
    setSelectedLocation(location);
    setDrawerOpen(true);
  });

  return (
    <>
      <div ref={mapContainer} className="w-full h-full" />
      <div className="absolute bottom-0 left-0 mb-5 ml-5 text-xs text-gray-500 pointer-events-none select-none">
        Zoom: {currentZoom}
      </div>
    </>
  );
}