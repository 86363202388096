import { useSpring, animated } from '@react-spring/web';

interface LoadingSpinnerProps {
  size?: 'sm' | 'md' | 'lg';
  color?: string;
}

export function LoadingSpinner({ 
  size = 'md', 
  color = '#4A90E2' 
}: LoadingSpinnerProps) {
  const spin = useSpring({
    from: { rotate: 0 },
    to: { rotate: 360 },
    loop: true,
    config: { duration: 1000 }
  });

  const sizeMap = {
    sm: 'w-4 h-4',
    md: 'w-6 h-6',
    lg: 'w-8 h-8'
  };

  return (
    <animated.div
      className={`${sizeMap[size]} border-2 border-t-transparent rounded-full`}
      style={{
        ...spin,
        borderColor: `${color} transparent transparent transparent`,
        transform: spin.rotate.to(r => `rotate(${r}deg)`)
      }}
    />
  );
}