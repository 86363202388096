import mapboxgl from 'mapbox-gl';

interface GeolocateEvent {
  coords: {
    latitude: number;
    longitude: number;
    accuracy: number;
    altitude: number | null;
    altitudeAccuracy: number | null;
    heading: number | null;
    speed: number | null;
  };
  timestamp: number;
}

export function setupNavigationControls(
  map: mapboxgl.Map, 
  container: HTMLDivElement,
  onGeolocate?: (coords: { latitude: number; longitude: number }) => void
): mapboxgl.GeolocateControl {
  const navigationControl = new mapboxgl.NavigationControl();
  const geolocateControl = new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true
    },
    trackUserLocation: false,
    showAccuracyCircle: false
  });

  if (onGeolocate) {
    geolocateControl.on('geolocate', (e: GeolocateEvent) => {
      if (e.coords) {
        onGeolocate(e.coords);
      }
    });
  }

  container.appendChild(navigationControl.onAdd(map));
  container.appendChild(geolocateControl.onAdd(map));

  return geolocateControl;
}