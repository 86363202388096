interface GoogleMapsLinkProps {
  location: {
    name: string;
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    postalCode?: string;
  };
}

export function GoogleMapsLink({ location }: GoogleMapsLinkProps) {
  const getGoogleMapsUrl = () => {
    const searchParams = [
      location.name,
      location.address,
      location.city,
      location.state,
      location.postalCode,
      location.country
    ]
      .filter(Boolean)
      .join(', ');

    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(searchParams)}`;
  };

  return (
    <a
      href={getGoogleMapsUrl()}
      target="_blank"
      rel="noopener noreferrer"
      className="p-1 hover:opacity-80 transition-opacity shrink-0"
      title="Open in Google Maps"
      onClick={(e) => e.stopPropagation()}
    >
      <svg 
        viewBox="0 0 24 24" 
        className="w-6 h-6 text-red-500"
        fill="currentColor"
      >
        <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
      </svg>
    </a>
  );
}