import OpenAI from 'openai';

// OpenAI configuration
export const OPENAI_CONFIG = {
  model: 'gpt-4o-mini-2024-07-18',
  speechModel: 'tts-1',
  voice: 'alloy'
} as const;

// OpenAI client instance
export const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

// Mapbox configuration
export const MAPBOX_API = 'https://api.mapbox.com';
export const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;