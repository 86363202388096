import type { MapContext } from '../../types';

// Query type detection
export function isLocationQuery(query: string): boolean {
  // Common location indicators
  const locationPatterns = [
    /\b(in|at|near|around)\b/i,  // Prepositions
    /\b(street|road|avenue|blvd|plaza|square|park)\b/i,  // Address elements
    /\b(city|town|village|county|state|country)\b/i,  // Geographic units
    /^[A-Z][a-z]+(?:[\s-][A-Z][a-z]+)*$/,  // Proper nouns (e.g., "New York")
    /^\d+\s+[A-Za-z\s]+$/  // Street addresses
  ];

  return locationPatterns.some(pattern => pattern.test(query));
}

export function isDiscoveryQuery(query: string): boolean {
  // Common discovery/category indicators
  const discoveryPatterns = [
    /\b(find|show|where|what|best|good|top|great|recommend)\b/i,  // Action words
    /\b(restaurants?|cafes?|shops?|stores?|markets?|museums?|parks?|attractions?|spots?|places?|locations?)\b/i,  // Place categories
    /\b(things?\s+to\s+do|places?\s+to\s+go|activities|events)\b/i,  // Activities
    /\b(food|coffee|drinks?|shopping|entertainment|sports?|arts?|picnic)\b/i  // Categories
  ];

  // Also consider simple category searches (e.g., "coffee shops", "picnic spots")
  const simplePatterns = [
    /^[a-z\s]+(shops?|spots?|places?|locations?)$/i  // Simple category + location type
  ];

  return discoveryPatterns.some(pattern => pattern.test(query)) ||
         simplePatterns.some(pattern => pattern.test(query));
}

export function isSimpleLocationSearch(query: string): boolean {
  // Check if the query is a simple location name without discovery terms
  return isLocationQuery(query) && !isDiscoveryQuery(query);
}

export function cleanOpenAIResponse(content: string): string {
  return content.replace(/```json\n?|\n?```/g, '').trim();
}

export function isWithinBounds(
  coordinates: [number, number],
  bounds?: [[number, number], [number, number]]
): boolean {
  if (!bounds) return true;
  
  const [lng, lat] = coordinates;
  const [[west, south], [east, north]] = bounds;
  
  return lng >= west && lng <= east && lat >= south && lat <= north;
}

export function calculateSearchRadius(bounds: [[number, number], [number, number]]): number {
  const [[west, south], [east, north]] = bounds;
  
  // Convert degrees to radians
  const toRad = (deg: number) => deg * Math.PI / 180;
  
  // Earth's radius in kilometers
  const R = 6371;
  
  // Calculate center point
  const centerLat = (north + south) / 2;
  const centerLng = (east + west) / 2;
  
  // Calculate corner point
  const cornerLat = north;
  const cornerLng = east;
  
  // Haversine formula
  const dLat = toRad(cornerLat - centerLat);
  const dLon = toRad(cornerLng - centerLng);
  const lat1 = toRad(centerLat);
  const lat2 = toRad(cornerLat);
  
  const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
           Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  
  // Return radius in kilometers
  return R * c;
}

export function getLoadingMessage(query: string, attempt?: number): string {
  if (!query) return "Searching for interesting places...";
  
  if (attempt && attempt > 1) {
    return `Expanding search area (attempt ${attempt})...`;
  }
  
  return `Finding ${query.toLowerCase().replace(/show me |find |what |where /i, '')}...`;
}