import { useState, useEffect, useRef } from 'react';
import { Combobox } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { AVAILABLE_TOPICS, TIMEFRAME_TOOLTIP } from '../../config/story';
import { StoryStyleSlider } from '../UI/StoryStyleSlider';
import { createPortal } from 'react-dom';
import type { Location, Topic, StoryStyle } from '../../types';

interface StoryControlsProps {
  location: Location;
  disabled?: boolean;
  onUpdate: (locationId: string, updates: Partial<Location>) => void;
}

export function StoryControls({ location, disabled, onUpdate }: StoryControlsProps) {
  const [timeframe, setTimeframe] = useState(location.timeframe || '');
  const [topics, setTopics] = useState(location.topics || []);
  const [inputValue, setInputValue] = useState('');
  const [storyStyle, setStoryStyle] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const comboboxRef = useRef<HTMLDivElement>(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

  useEffect(() => {
    setInputValue(topics.join(', '));
  }, [topics]);

  useEffect(() => {
    if (isOpen && comboboxRef.current) {
      const rect = comboboxRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.top,
        left: rect.left,
        width: rect.width
      });
    }
  }, [isOpen]);

  const handleTimeframeChange = (value: string) => {
    setTimeframe(value);
    onUpdate(location.id, { timeframe: value });
  };

  const handleTopicSelect = (topic: string) => {
    if (!topics.includes(topic as Topic)) {
      const newTopics = [...topics, topic as Topic];
      setTopics(newTopics);
      onUpdate(location.id, { topics: newTopics });
    }
    setIsOpen(false);
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
    const topicList = value.split(',').map(t => t.trim()).filter(Boolean) as Topic[];
    setTopics(topicList);
    onUpdate(location.id, { topics: topicList });
  };

  const handleStyleChange = (value: number | number[]) => {
    const numValue = Array.isArray(value) ? value[0] : value;
    setStoryStyle(numValue);
    const style: StoryStyle = numValue === 0 ? 'fact' : numValue === 100 ? 'fantasy' : 'balanced';
    onUpdate(location.id, { storyStyle: style });
  };

  const renderDropdown = () => {
    if (!isOpen) return null;

    return createPortal(
      <div 
        className="fixed"
        style={{
          top: `${dropdownPosition.top - 210}px`,
          left: `${dropdownPosition.left}px`,
          width: `${dropdownPosition.width}px`,
          zIndex: 9999
        }}
      >
        <Combobox.Options 
          static
          className="w-full py-1 bg-white rounded-md shadow-lg overflow-auto border border-gray-200"
          style={{ maxHeight: '200px' }}
        >
          {AVAILABLE_TOPICS.map((topic) => (
            <Combobox.Option
              key={topic}
              value={topic}
              className={({ active }) =>
                `px-3 py-2 text-sm cursor-pointer ${
                  active ? 'bg-[#346DFF] text-white' : 'text-gray-900'
                }`
              }
            >
              {topic}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>,
      document.body
    );
  };

  return (
    <div className="space-y-2">
      <p className="text-gray-600">{location.description}</p>
      
      <div className="flex flex-col gap-4 sm:flex-row sm:gap-4 sm:items-end">
        <div className="w-full sm:w-1/3 relative">
          <div className="flex items-center gap-1">
            <label className="text-sm text-gray-600">Timeframe</label>
            <div className="group relative">
              <QuestionMarkCircleIcon className="h-4 w-4 text-gray-400 cursor-help" />
              <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-3 py-2 bg-gray-800 text-white text-xs rounded whitespace-pre opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none z-50">
                {TIMEFRAME_TOOLTIP}
              </div>
            </div>
          </div>
          <input
            type="text"
            value={timeframe}
            onChange={(e) => handleTimeframeChange(e.target.value)}
            disabled={disabled}
            className="w-full px-3 py-1.5 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#346DFF] focus:border-[#346DFF] disabled:bg-gray-100"
          />
        </div>

        <div className="w-full sm:w-1/3 relative">
          <label className="text-sm text-gray-600">Topics</label>
          <div ref={comboboxRef} className="relative">
            <Combobox
              as="div"
              value=""
              onChange={handleTopicSelect}
            >
              <div className="relative">
                <Combobox.Input
                  className="w-full px-3 py-1.5 pr-8 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#346DFF] focus:border-[#346DFF] disabled:bg-gray-100"
                  value={inputValue}
                  onChange={(e) => handleInputChange(e.target.value)}
                  onClick={() => setIsOpen(true)}
                />
                <button
                  type="button"
                  onClick={() => setIsOpen(!isOpen)}
                  className="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <svg
                    className={`h-5 w-5 text-gray-400 transform transition-transform ${
                      isOpen ? 'rotate-180' : ''
                    }`}
                    viewBox="0 0 20 20"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              {renderDropdown()}
            </Combobox>
          </div>
        </div>

        <div className="w-full sm:w-1/3">
          <label className="text-sm text-gray-600">Style</label>
          <StoryStyleSlider
            value={storyStyle}
            onChange={handleStyleChange}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}