import { useState } from 'react';
import { useMapStore } from '../../store/mapStore';
import { 
  isLocationQuery,
  isDiscoveryQuery,
  isSimpleLocationSearch,
  getLoadingMessage
} from '../../services/api/utils';
import { searchLocations } from '../../services/api/search';
import { geocodeLocation } from '../../services/api/geocoding';
import { MagnifyingGlassIcon, XMarkIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { LoadingSpinner } from '../UI/LoadingSpinner';

const MIN_ZOOM = 3;
const MAX_ZOOM = 16;

export function SearchBar() {
  const [inputValue, setInputValue] = useState('');
  const { 
    setLocations, 
    setLoading,
    setLoadingMessage,
    setError, 
    setDrawerOpen, 
    mapCenter,
    loading,
    setQuery,
    map,
    goBack,
    mapHistory,
    currentHistoryIndex,
    setLastSearchParams,
    fitToLocations
  } = useMapStore();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const trimmedInput = inputValue.trim();
    
    if (!trimmedInput || !map) {
      setError('Please enter a search term');
      return;
    }

    try {
      setLoading(true);
      setLoadingMessage(getLoadingMessage(trimmedInput));
      setError(null);
      setQuery(trimmedInput);

      if (isSimpleLocationSearch(trimmedInput)) {
        const coordinates = await geocodeLocation(trimmedInput);
        if (coordinates) {
          map.flyTo({
            center: coordinates,
            zoom: Math.min(10, MAX_ZOOM),
            essential: true
          });
          setLocations([]);
          setDrawerOpen(false);
          setLastSearchParams(null);
        } else {
          throw new Error('Location not found. Please try a different search term.');
        }
      } else if (isDiscoveryQuery(trimmedInput)) {
        const bounds = map.getBounds();
        if (!bounds) {
          throw new Error('Please wait for the map to fully load and try again.');
        }
        
        setLastSearchParams({
          searchFn: searchLocations,
          query: trimmedInput
        });

        const results = await searchLocations(
          trimmedInput, 
          {
            center: mapCenter,
            bounds: [
              [bounds.getWest(), bounds.getSouth()],
              [bounds.getEast(), bounds.getNorth()]
            ],
            zoom: Math.min(Math.max(map.getZoom(), MIN_ZOOM), MAX_ZOOM)
          },
          (attempt) => {
            setLoadingMessage(getLoadingMessage(trimmedInput, attempt));
          }
        );

        setLocations(results);
        setDrawerOpen(true);
        fitToLocations();
      } else {
        throw new Error('Try adding words like "best" or "near" (e.g., "best coffee" or "museums near me")');
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
      setLoadingMessage("");
      setQuery('');
    }
  };

  const handleClear = () => {
    setInputValue('');
    setLocations([]);
    setDrawerOpen(false);
    setError(null);
    setQuery('');
  };

  const handleBack = () => {
    handleClear();
    goBack();
  };

  const canGoBack = currentHistoryIndex > 0 && mapHistory.length > 1;

  return (
    <div className="absolute top-4 left-0 right-0 z-20">
      <div className="px-4 w-full">
        <div className="flex items-center gap-3">
          <button
            onClick={handleBack}
            disabled={!canGoBack}
            className={`h-10 w-10 flex items-center justify-center rounded-lg shadow-lg bg-white transition-all duration-200 ${
              canGoBack 
                ? 'hover:bg-gray-100 opacity-100 cursor-pointer' 
                : 'opacity-30 cursor-not-allowed'
            }`}
            title={canGoBack ? "Go back to previous location" : "No previous location"}
          >
            <ArrowLeftIcon className="h-5 w-5 text-gray-600" />
          </button>

          <form onSubmit={handleSubmit} className="flex-1 max-w-[calc(100%-6rem-25px)]">
            <div className="relative">
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Search for a place or discover interesting locations..."
                className="w-full h-10 pl-4 pr-20 rounded-lg shadow-lg border-0 focus:ring-2 focus:ring-[#346DFF] text-sm"
                disabled={loading}
              />
              {inputValue && !loading && (
                <button
                  type="button"
                  onClick={handleClear}
                  className="absolute right-12 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              )}
              <button
                type="submit"
                disabled={!inputValue.trim() || loading}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 text-[#346DFF] hover:text-[#2855CC] disabled:text-gray-300"
              >
                {loading ? (
                  <LoadingSpinner size="sm" />
                ) : (
                  <MagnifyingGlassIcon className="h-5 w-5" />
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}