import { openai, OPENAI_CONFIG } from './config';
import { cleanOpenAIResponse, isWithinBounds } from './utils';
import { geocodeAddress } from './geocoding';
import { getGeographicContext } from './location';
import type { Location, MapContext } from '../../types';
import { SEARCH_LOCATIONS_PROMPT } from './prompts';

export const searchLocations = async (
  query: string, 
  mapContext: MapContext,
  onAttempt?: (attempt: number) => void
): Promise<Location[]> => {
  let attempt = 1;
  let maxAttempts = 3;
  let currentBounds = mapContext.bounds;

  while (attempt <= maxAttempts) {
    try {
      onAttempt?.(attempt);

      const geographicContext = await getGeographicContext(
        mapContext.center,
        currentBounds || [
          [mapContext.center[0] - 0.1, mapContext.center[1] - 0.1],
          [mapContext.center[0] + 0.1, mapContext.center[1] + 0.1]
        ]
      );

      const completion = await openai.chat.completions.create({
        model: OPENAI_CONFIG.model,
        messages: [{
          role: "system",
          content: SEARCH_LOCATIONS_PROMPT
        }, {
          role: "user",
          content: `Find locations matching: ${query}\nGeographic context: ${geographicContext}`
        }],
        temperature: 0.7,
        response_format: { type: "json_object" }
      });

      const content = completion.choices[0]?.message?.content;
      if (!content) {
        throw new Error('No suggestions received from OpenAI');
      }

      let suggestions: any[];
      try {
        const cleanedContent = cleanOpenAIResponse(content);
        const parsedContent = JSON.parse(cleanedContent);
        
        if (!parsedContent.locations || !Array.isArray(parsedContent.locations)) {
          throw new Error('Invalid response format from OpenAI');
        }
        
        suggestions = parsedContent.locations;
      } catch (error) {
        console.error('Failed to parse OpenAI response:', content);
        throw new Error('Invalid response format from OpenAI');
      }

      const validatedLocations = await Promise.all(
        suggestions.map(async (suggestion, index) => {
          try {
            if (!suggestion.name || !suggestion.description) {
              console.warn('Invalid location data:', suggestion);
              return null;
            }

            const coordinates = await geocodeAddress(suggestion, mapContext);
            if (!coordinates) {
              console.warn('Could not geocode location:', suggestion.name);
              return null;
            }

            if (!isWithinBounds(coordinates, currentBounds)) {
              console.warn('Location outside bounds:', suggestion.name);
              return null;
            }

            return {
              id: `loc-${Date.now()}-${index}`,
              name: suggestion.name,
              description: suggestion.description,
              coordinates,
              address: suggestion.address || undefined,
              city: suggestion.city || undefined,
              state: suggestion.state || undefined,
              country: suggestion.country || undefined,
              postalCode: suggestion.postalCode || undefined
            } as Location;
          } catch (error) {
            console.warn('Error processing location:', error);
            return null;
          }
        })
      );

      const locations = validatedLocations.filter((loc): loc is Location => loc !== null);
      
      if (locations.length >= 3 || attempt === maxAttempts) {
        return locations;
      }

      if (currentBounds) {
        const [[west, south], [east, north]] = currentBounds;
        const expandFactor = 1.5;
        const lngDiff = (east - west) * (expandFactor - 1) / 2;
        const latDiff = (north - south) * (expandFactor - 1) / 2;
        
        currentBounds = [
          [west - lngDiff, south - latDiff],
          [east + lngDiff, north + latDiff]
        ];
      }

      attempt++;
    } catch (error) {
      console.error('Error searching locations:', error);
      
      if (error instanceof Error) {
        if (error.message.includes('rate limits exceeded')) {
          throw new Error('Search limit reached. Please try again in a moment.');
        } else if (error.message.includes('API key')) {
          throw new Error('Service configuration error. Please try again later.');
        } else if (error.message.includes('Invalid response format')) {
          throw new Error('Unable to process search results. Please try again.');
        } else {
          throw error;
        }
      }
      
      throw new Error('An unexpected error occurred. Please try again.');
    }
  }

  throw new Error('No locations found. Try adjusting your search terms or zooming to a different area.');
};