import React from 'react';
import { useUser } from '@clerk/clerk-react';
import { useMapStore } from '../../store/mapStore';
import { Map } from '../Mapping/Map';
import { SearchBar } from '../Search/SearchBar';
import { DiscoveryDrawer } from '../Drawers/DiscoveryDrawer';
import { DetailDrawer } from '../Drawers/DetailDrawer';
import { LoadingOverlay } from '../UI/LoadingOverlay';
import { ErrorAlert } from '../UI/ErrorAlert';
import { AuthOverlay } from '../Auth/AuthOverlay';
import { UserButton } from '../UI/UserButton';

export function MainLayout() {
  const { isSignedIn, isLoaded } = useUser();
  const { 
    loading, 
    loadingMessage, 
    error, 
    locations, 
    isDrawerOpen,
    map,
    retrySearch 
  } = useMapStore();

  const showDiscoveryDrawer = isDrawerOpen && locations.length >= 1;
  const showDetailDrawer = isDrawerOpen && locations.length === 1;

  const handleRetry = () => {
    if (map) {
      const currentZoom = map.getZoom();
      const newZoom = Math.max(currentZoom - 2, 4);
      
      map.flyTo({
        zoom: newZoom,
        essential: true,
        duration: 1000
      });

      setTimeout(() => {
        retrySearch();
      }, 1000);
    }
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <div className="h-screen w-screen relative">
      {!isSignedIn && <AuthOverlay />}
      
      <SearchBar />
      <UserButton />
      
      <LoadingOverlay
        isVisible={loading}
        message={loadingMessage}
      />
      
      {error && <ErrorAlert message={error} onRetry={handleRetry} />}

      <Map />
      {showDiscoveryDrawer && <DiscoveryDrawer />}
      {showDetailDrawer && <DetailDrawer />}
    </div>
  );
}