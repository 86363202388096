import { useCallback } from 'react';
import { useMapStore } from '../../../store/mapStore';
import { reverseGeocode } from '../../../services/api';
import type { MapMouseEvent } from 'mapbox-gl';
import { MIN_ZOOM, MAX_ZOOM } from '../constants';
import type { Location } from '../../../types';

export function useMapHandlers() {
  const { 
    locations,
    setLocations,
    setSelectedLocation,
    setDrawerOpen,
    query,
    isDrawerOpen,
    setError,
    map
  } = useMapStore();

  const isDiscoveryMode = locations.length > 1;

  const handleClick = useCallback(async (e: MapMouseEvent & { lngLat: { lng: number; lat: number } }) => {
    console.log('Map click detected', {
      query,
      isDrawerOpen,
      isDiscoveryMode,
      locationsCount: locations.length
    });

    if (query || (isDiscoveryMode && isDrawerOpen)) {
      console.log('Click blocked: active search or discovery mode');
      return;
    }

    if (!map) {
      console.log('Click blocked: map not initialized');
      return;
    }

    const { lng, lat } = e.lngLat;
    const currentZoom = map.getZoom();

    console.log('Processing click at coordinates:', { lng, lat, zoom: currentZoom });

    try {
      const locationInfo = await reverseGeocode(
        [lng, lat],
        currentZoom
      );

      console.log('Reverse geocode result:', locationInfo);

      map.flyTo({
        center: [lng, lat],
        zoom: Math.min(Math.max(currentZoom, MIN_ZOOM), MAX_ZOOM),
        essential: true
      });

      const newLocation: Location = {
        id: `loc-${Date.now()}`,
        name: locationInfo.name,
        description: `Discover the story of ${locationInfo.name}`,
        coordinates: [lng, lat],
        locationType: locationInfo.type
      };

      console.log('Creating new location:', newLocation);

      setLocations([newLocation]);
      setSelectedLocation(newLocation);
      setDrawerOpen(true);

      console.log('Location set and drawer opened');
    } catch (error) {
      console.error('Error processing click:', error);
      setError(error instanceof Error ? error.message : 'Failed to identify location');
    }
  }, [query, map, isDrawerOpen, isDiscoveryMode, locations.length, setLocations, setSelectedLocation, setDrawerOpen, setError]);

  const handleDoubleClick = useCallback((e: MapMouseEvent & { lngLat: { lng: number; lat: number } }) => {
    e.preventDefault();
    
    if (!query && map) {
      const { lng, lat } = e.lngLat;
      const currentZoom = map.getZoom();
      map.flyTo({
        center: [lng, lat],
        zoom: Math.min(currentZoom + 1, MAX_ZOOM),
        essential: true
      });
    }
  }, [query, map]);

  return {
    handleClick,
    handleDoubleClick
  };
}