import { MAPBOX_API, MAPBOX_TOKEN } from './config';
import type { MapContext } from '../../types';

export async function geocodeAddress(location: {
  name: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
}, mapContext?: MapContext): Promise<[number, number] | null> {
  if (!MAPBOX_TOKEN) {
    throw new Error('Mapbox access token is missing');
  }

  const searchQuery = [
    location.name,
    location.address,
    location.city,
    location.state,
    location.postalCode,
    location.country
  ].filter(Boolean).join(', ');

  try {
    const response = await fetch(
      `${MAPBOX_API}/geocoding/v5/mapbox.places/${encodeURIComponent(searchQuery)}.json?access_token=${MAPBOX_TOKEN}`
    );

    if (!response.ok) {
      throw new Error(`Mapbox API error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    
    if (!data.features?.length) {
      return null;
    }

    const [lng, lat] = data.features[0].center;
    return [lng, lat];
  } catch (error) {
    console.error('Error geocoding address:', error instanceof Error ? error.message : 'Unknown error');
    return null;
  }
}

export async function geocodeLocation(query: string): Promise<[number, number] | null> {
  if (!MAPBOX_TOKEN) {
    throw new Error('Mapbox access token is missing');
  }

  try {
    const response = await fetch(
      `${MAPBOX_API}/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json?access_token=${MAPBOX_TOKEN}`
    );

    if (!response.ok) {
      throw new Error(`Mapbox API error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    
    if (!data.features?.length) {
      return null;
    }

    const [lng, lat] = data.features[0].center;
    return [lng, lat];
  } catch (error) {
    console.error('Error geocoding location:', error instanceof Error ? error.message : 'Unknown error');
    throw new Error('Failed to find location. Please try a different search term.');
  }
}

export async function reverseGeocode(coordinates: [number, number], zoom: number): Promise<{ name: string; type: string }> {
  if (!MAPBOX_TOKEN) {
    throw new Error('Mapbox access token is missing');
  }

  try {
    const [lng, lat] = coordinates;
    const response = await fetch(
      `${MAPBOX_API}/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${MAPBOX_TOKEN}`
    );

    if (!response.ok) {
      throw new Error(`Mapbox API error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    
    if (!data.features?.length) {
      throw new Error('No location found at these coordinates');
    }

    const feature = getFeatureByZoomLevel(data.features, zoom);
    if (!feature) {
      throw new Error('Could not determine location details');
    }

    return {
      name: feature.text,
      type: feature.place_type[0]
    };
  } catch (error) {
    console.error('Error reverse geocoding:', error instanceof Error ? error.message : 'Unknown error');
    throw new Error('Could not identify this location. Please try a different spot.');
  }
}

const getFeatureByZoomLevel = (features: any[], zoom: number): any => {
  const ZOOM_RANGES = {
    country: { min: 0, max: 3.4 },
    region: { min: 3.5, max: 6.5 },
    place: { min: 6.6, max: 11 },
    neighborhood: { min: 11.1, max: 13 },
    poi: { min: 13.1, max: 16 }
  };

  const targetTypes = Object.entries(ZOOM_RANGES)
    .filter(([_, range]) => zoom >= range.min && zoom <= range.max)
    .map(([type]) => type);

  let feature = features.find(f => targetTypes.includes(f.place_type[0]));

  if (!feature) {
    if (zoom > 11) {
      feature = features.find(f => ['poi', 'venue'].includes(f.place_type[0]));
    }
    else if (zoom > 6) {
      feature = features.find(f => ['place', 'locality'].includes(f.place_type[0]));
    }
    else {
      feature = features.find(f => ['country', 'region'].includes(f.place_type[0]));
    }
  }

  if (!feature) {
    const preferredOrder = ['poi', 'venue', 'address', 'neighborhood', 'locality', 'place', 'region', 'country'];
    
    for (const type of preferredOrder) {
      feature = features.find(f => f.place_type[0] === type);
      if (feature) break;
    }
  }

  return feature || features[0];
};