import { UserButton as ClerkUserButton } from "@clerk/clerk-react";

export function UserButton() {
  return (
    <div className="absolute top-4 right-[calc(40px-1.25rem)] z-50">
      <div className="w-10 h-10 flex items-center justify-center">
        <ClerkUserButton 
          appearance={{
            elements: {
              rootBox: "w-10 h-10",
              userButtonTrigger: "w-10 h-10",
              userButtonAvatarBox: "w-10 h-10",
              userButtonPopoverCard: "top-12 right-0"
            }
          }}
        />
      </div>
    </div>
  );
}