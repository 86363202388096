import { animated, useTransition } from '@react-spring/web';
import { LoadingSpinner } from './LoadingSpinner';

interface LoadingOverlayProps {
  message: string;
  isVisible: boolean;
}

export function LoadingOverlay({ message, isVisible }: LoadingOverlayProps) {
  const transition = useTransition(isVisible && message, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { tension: 300, friction: 20 }
  });

  return transition((style, item) =>
    item && (
      <animated.div
        style={style}
        className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
      >
        <div className="bg-white rounded-lg p-6 shadow-xl max-w-sm w-full mx-4">
          <div className="flex flex-col items-center space-y-4">
            <LoadingSpinner size="lg" />
            <p className="text-gray-700 text-center font-medium">{message}</p>
          </div>
        </div>
      </animated.div>
    )
  );
}