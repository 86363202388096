import { Slider } from '@nextui-org/react';

interface StoryStyleSliderProps {
  value: number;
  onChange: (value: number | number[]) => void;
  disabled?: boolean;
}

export function StoryStyleSlider({ value, onChange, disabled }: StoryStyleSliderProps) {
  return (
    <div className="flex flex-col gap-2 pr-2.5">
      <Slider
        size="sm"
        step={50}
        maxValue={100}
        minValue={0}
        value={value}
        onChange={onChange}
        isDisabled={disabled}
        className="max-w-md"
        classNames={{
          base: "gap-3",
          track: "bg-[#346DFF]",
          thumb: "bg-[#346DFF]",
          mark: "hidden",
          label: "hidden"
        }}
        aria-label="Story style"
      />
      <div className="flex justify-between text-xs text-gray-600 px-1">
        <span>Fact</span>
        <span>Balanced</span>
        <span>Fantasy</span>
      </div>
    </div>
  );
}