import { MainLayout } from './components/Layout/MainLayout';
import { RedirectToSignIn, SignedIn, SignedOut } from '@clerk/clerk-react';

function App() {
  return (
    <>
      <SignedIn>
        <MainLayout />
      </SignedIn>
      <SignedOut>
        <MainLayout />
      </SignedOut>
    </>
  );
}

export default App;