import type { LayerConfig } from '../types';
import type { Map as MapboxMap } from 'mapbox-gl';

export function setupLayerControls(
  map: MapboxMap,
  container: HTMLDivElement,
  layerConfigs: LayerConfig[]
): void {
  const layerList = document.createElement('div');
  layerList.className = 'absolute top-20 right-[calc(40px-1.25rem)] bg-white/90 backdrop-blur-sm rounded-lg shadow-lg z-10 flex flex-col gap-0.5 p-0.5';

  layerConfigs.forEach(layer => {
    const button = document.createElement('button');
    button.className = 'w-8 h-8 flex items-center justify-center hover:bg-gray-100 rounded transition-colors duration-200 group';
    button.title = layer.title;

    const icon = document.createElement('div');
    icon.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-5 h-5 text-gray-600 group-hover:text-gray-800">
        <path stroke-linecap="round" stroke-linejoin="round" d="${layer.icon}" />
      </svg>
    `;

    button.appendChild(icon);
    button.onclick = () => {
      const styleUrl = `mapbox://styles/mapbox/${layer.id}`;
      map.setStyle(styleUrl);
    };
    layerList.appendChild(button);
  });

  container.appendChild(layerList);
}