import { SignIn } from "@clerk/clerk-react";
import { animated, useSpring } from "@react-spring/web";

export function AuthOverlay() {
  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 300 }
  });

  return (
    <animated.div 
      style={fadeIn}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm"
    >
      <div className="bg-white rounded-lg shadow-xl overflow-hidden">
        <SignIn 
          appearance={{
            elements: {
              rootBox: "mx-auto",
              card: "border-0 shadow-none",
            }
          }}
        />
      </div>
    </animated.div>
  );
}