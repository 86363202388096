export const TIMEFRAME_TOOLTIP = "The Timeframe is quite smart.\nEnter dates like 1800's or text like Bronze Age";

export const AVAILABLE_TOPICS = [
  'Adventure',
  'Art',
  'Culture',
  'Geography',
  'Geology',
  'History',
  'Indigenous people',
  'Outdoors',
  'People',
  'Romance',
  'Science'
] as const;

export type Topic = typeof AVAILABLE_TOPICS[number];